<template>
  <y_layout>
    <section class="flex-def flex-zCenter flex-cCenter flex-zTopBottom" style="background-color: #FFFFFF;min-height: 30rem;padding-bottom: 5rem">
      <choose_mode :is_tpl="true" @change="modeChoose"></choose_mode>
    </section>

  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import choose_mode from "@/view/active/add/choose_mode";
export default {
  components:{y_layout,choose_mode},
  name: "mode",
  methods:{
    modeChoose(mode){
      this.$router.push({
        name:"tplModeList",
        params:{
          ...this.$route.params,
          mode
        }
      })
    },
  }
}
</script>

<style scoped>

</style>