<template>
  <div class="flex-def flex-cCenter flex-zCenter"
       style="max-width: 800px;padding-bottom: 2rem;margin-top: 2rem;font-size: 1rem;position:relative;">
    <div @click="modeChoose(1)" class="mode-item active y-pointer flex-zTopBottom flex-def flex-zCenter flex-cCenter">
      <div class="mode-icon active flex-def flex-zCenter flex-cCenter">
        <i class="el-icon-wind-power"></i>
      </div>
      <div style="text-align: center;height: 2rem">通用模式 <br> (联盟模式)</div>
    </div>
    <div v-if="addons.video && !is_tpl" @click="modeChoose(2)"
         class="mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom">
      <div class="mode-icon flex-def flex-zCenter flex-cCenter">
        <i class="el-icon-video-camera"></i>
      </div>
      <div style="height: 2rem">短视频模式</div>
    </div>
    <div v-if="addons.classic" @click="modeChoose(3)"
         class="mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom">
      <div class="mode-icon flex-def flex-zCenter flex-cCenter">
        <i class="el-icon-s-management"></i>
      </div>
      <div style="height: 2rem;text-align: center">单/多商家 <br>极差模式</div>
    </div>
    <div v-if="addons.group_buy" @click="modeChoose(4)"
         class="mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom">
      <div class="mode-icon flex-def flex-zCenter flex-cCenter">
        <i class="el-icon-c-scale-to-original"></i>
      </div>
      <div style="height: 2rem">拼团模式</div>
    </div>
    <div v-if="addons.free" @click="modeChoose(5)"
         class="mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom">
      <div class="mode-icon flex-def flex-zCenter flex-cCenter">
        <i class="el-icon-shopping-cart-full"></i>
      </div>
      <div style="height: 2rem">免费模式</div>
    </div>
    <div v-if="addons.cdkey" @click="modeChoose(6)"
         class="mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom">
      <div class="mode-icon flex-def flex-zCenter flex-cCenter">
        <i class="el-icon-set-up"></i>
      </div>
      <div style="height: 2rem">激活码模式</div>
    </div>
    <div @click="$message.error('增值插件正在规划中')"
         class="mode-item y-pointer flex-def flex-zCenter flex-cCenter flex-zTopBottom">
      <div class="mode-icon flex-def flex-zCenter flex-cCenter">
        <i class="el-icon-document-checked"></i>
      </div>
      <div style="height: 2rem">投票模式</div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  name: "choose_mode",
  props:{
    is_tpl:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return{
      addons:{
        video:false,
      }
    }
  },
  mounted() {
    this.load().then(res=>{
      this.addons = res;
    })
  },
  methods: {
    modeChoose(mode) {
      this.$emit('change', mode);
    },
    ...mapActions("addons",["load"])
  }
}
</script>

<style scoped>
.mode-icon {
  height: 4rem;
  width: 4rem;
  border-radius: 5rem;
  background-color: #6a44e3;
  color: #FFFFFF;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.mode-icon.active {
  background-color: #FFFFFF;
  color: #6a44e3;
}

.mode-item {
  border-radius: .5rem;
  height: 12rem;
  width: 10rem;
  background-color: #ecf0fc;
  margin-left: 2rem;
  color: #6540d8;
}

.mode-item.active {
  background-color: #6a44e3;
  color: #FFFFFF;
}

.mode-item:first-child {
  margin-left: unset;
}
</style>